import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { Layout } from 'layouts';
import {
  PageHeader,
  HexagonImage,
  TechnologyPartners,
  BrandsWorkedWithUs,
} from '@components';
import headerImage from '../../static/industries/header.jpeg';
import headingIcon from '@static/icons/Industries_heading_icon.svg';
import Heading from '@components/pageHeading';
import PageNavigation from '@components/pageNavigation';
import pageContent from '@constants/industries';
import theme from '../../config/theme';
import { applicationURLs } from '../routes';

const IndustryOverviewSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.colors.white.lightest};
  &:first-child {
    padding-top: 50px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 90%;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
`;

const IndustryOverviewWrapper = styled.div`
  display: flex;
  max-width: 1000px;
  margin: 40px 0;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    flex-direction: column;
    align-items: center;
  }
`;

const IndustryContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: self-start;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    position: relative;
    width: 70%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    align-items: center;
  }
`;

const IndustryImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    position: absolute;
    top: 0;
    right: -50px;
    bottom: 0;
    width: 30%;
  }
`;

const IndustryTitle = styled.h2`
  font-size: 34px;
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 70px;
  margin-bottom: 15px;

  &:after {
    content: '';
    position: absolute;
    width: 55px;
    right: 0;
    height: 2px;
    margin-top: 2px;
    background: ${(props) => props.theme.colors.primary.base};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    text-align: center;
    margin-bottom: 20px;
    padding: 0 0 12px 0;

    &:after {
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
    }
  }
`;

const IndustryText = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.black.base};
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    text-align: center;
  }
`;

const ExploreButton = styled(Link)`
  display: inline-block;
  font-size: 16px;
  color: ${(props) => props.theme.colors.white.base};
  background: ${(props) => props.theme.colors.primary.base};
  font-weight: bold;
  height: 45px;
  line-height: 45px;
  border-radius: 25px;
  width: 150px;
  text-align: center;
  transition: all 0.4s;

  &:hover {
    color: ${(props) => props.theme.colors.white.base};
    background: ${(props) => props.theme.colors.primary.light};
  }
`;

class Industries extends Component {
  render() {
    return (
      <Layout themeType="dark">
        <PageHeader
          image={headerImage}
          title={'Industries'}
          icon={headingIcon}
        />
        <Heading title={pageContent.heading} overview={pageContent.overview} />

        {this.renderPageIndustries()}

        <PageNavigation
          prev={pageContent.navigation.prev}
          prevLink={applicationURLs.SERVICES}
          next={pageContent.navigation.next}
          nextLink={applicationURLs.TECHNOLOGIES}
        />

        <TechnologyPartners />

        <BrandsWorkedWithUs noBackground />
      </Layout>
    );
  }

  renderPageIndustries() {
    return pageContent.industries.map((industry, idx) => {
      let overviewParagraphs = industry.overview.split('\n');
      return (
        <IndustryOverviewSection key={idx}>
          <IndustryOverviewWrapper>
            <IndustryImageWrapper>
              <HexagonImage src={industry.headerImage} />
            </IndustryImageWrapper>
            <IndustryContentWrapper>
              <IndustryTitle>{industry.title}</IndustryTitle>
              {overviewParagraphs.map((paragraph, idx) => (
                <IndustryText key={idx}>{paragraph}</IndustryText>
              ))}
              <ExploreButton to={industry.pageLink}>Explore</ExploreButton>
            </IndustryContentWrapper>
          </IndustryOverviewWrapper>
        </IndustryOverviewSection>
      );
    });
  }
}

export default Industries;
